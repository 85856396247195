<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->

      <div class="row">
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg ecosistema-elc"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              <v-avatar size="56">
                <img
                  alt="Nivel de electrificación"
                  src="/media/cide/ecosistemas/ELC.png"
                />
              </v-avatar>

              <h1 class="ml-3 texto-ecosistema-elc">
                Nivel de electrificación. {{ this.currentObject.nombre }}
              </h1>

              <v-spacer></v-spacer>
              <v-select
                :items="this.$regions"
                item-text="nombre"
                item-value="slug"
                v-model="selected"
                v-on:change="changeComunidad"
              ></v-select>

              <v-spacer></v-spacer>

              <router-link
                to="/nivel-electrificacion"
                class="btn btn-success font-weight-bolder font-size-sm"
                >Volver a los datos nacionales</router-link
              >
            </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-xl-8"></div>
                <div class="col-xl-2">Media Nacional</div>
                <div class="col-xl-2">{{ this.currentObject.nombre }}</div>
              </div>

              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de electrificación en zonas rurales
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson.comunidad }}
                  </span>
                  kWh convertidos de gas a electricidad en hogares
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson.media }}
                  </span>
                  kWh convertidos de gas a electricidad en hogares
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-4">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title> </v-card-title>

            <v-card-text>
              <fusioncharts
                :type="this.currentObject.tipo_mapa"
                width="100%"
                height="500"
                dataFormat="jsonurl"
                :dataSource="
                  '/data/ELC/comunidad/' + this.currentSlug + '_mapa_inp01.json'
                "
              >
              </fusioncharts>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-4">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title
              >Grado de electrificación por tarifa (kWh
              anuales/CUPS)</v-card-title
            >
            <v-card-text>
              <fusioncharts
                type="column2d"
                width="100%"
                height="500"
                dataFormat="jsonurl"
                :dataSource="
                  '/data/ELC/comunidad/' + this.currentSlug + '_inp01.json'
                "
              >
              </fusioncharts>
            </v-card-text>
          </v-card>
        </div>

        <div class="col-xl-4">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Peso del coste eléctrico en el consumo energético
              doméstico</v-card-title
            >

            <v-card-text>
              <fusioncharts
                type="pie3d"
                width="100%"
                height="400"
                dataFormat="jsonurl"
                :dataSource="
                  '/data/ELC/comunidad/' + this.currentSlug + '_inp07.json'
                "
              >
              </fusioncharts>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      selected: this.$route.params.comunidad,
      selectedVariable:
        "Contribución a la descarbonización del Vehículo Eléctrico",
      selectedYear: "2020",
      selectedOption: "Todos",
      dataJson: [],
    };
  },
  computed: {
    currentSlug() {
      return this.$route.params.comunidad;
    },
    currentObject() {
      let selectedRegion = this.$regions.filter((comunidad) => {
        return comunidad.slug == this.$route.params.comunidad;
      });
      return selectedRegion[0];
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getJsonData();
  },
  methods: {
    changeComunidad(selected) {
      this.$router.push(
        {
          name: "nivel-electrificacion-comunidad",
          params: { comunidad: selected },
        },
        () => {
          this.getJsonData();
        }
      );
    },
    getJsonData() {
      fetch("/data/ELC/comunidad/" + this.currentSlug + "_data.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson = data;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
